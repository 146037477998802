import React, { Component, useContext, useState, useEffect, useRef } from "react";
import { Image, Dimensions, Text, Alert, Animated, Easing, StyleSheet, View, InteractionManager , TouchableOpacity } from "react-native";

import GameContext from "../context/GameContext";
import { useNavigation } from "@react-navigation/native";

import Icon from 'react-native-vector-icons/Ionicons';

function GameStart({ ...props }) {
  /* ================================ For Mobile Responsive ===============================*/
  const {
    // set the socket to the context
    socket, setSocket,
    // set gameMode to the context
    gameMode, role
  } = React.useContext(GameContext);
  const [evalWidth, setEvalWidth] = useState(768);
  const [isMobile, setIsMobile] = useState(Dimensions.get('window').width < evalWidth);
  const [isPC, setIsPC] = useState(Dimensions.get('window').width >= evalWidth);

  useEffect(() => {
    const handleSocketPlayGame = (data) => {
      // window.alert("I will play as a ", data.role);
      props.onPlay();
    }

    // getWalletAddress();
  
    InteractionManager.runAfterInteractions((_) => {
      Animated.timing(fadeAnim, {
        useNativeDriver: true,
        toValue: 1,
        duration: 800,
        delay: 0,
        easing: Easing.in(Easing.qubic),
      }).start();
    });
    const handleResize = () => {
      setIsMobile(window.innerWidth < evalWidth);
      setIsPC(window.innerWidth >= evalWidth);
    };

    const handleKeyUp = ({ keyCode }) => {
      
      if ([32].includes(keyCode)||[38].includes(keyCode)) {
        if (gameMode == 2) {
          // window.alert("play game :", role);
          socket.emit('message', JSON.stringify({
            cmd: 'PLAY_GAME',
            role: role
          }));
        } else {
          props.onPlay();
        }
      }
    };



    window.addEventListener("keyup", handleKeyUp, false);
    window.addEventListener('resize', handleResize);
    
    socket.on('PLAY_GAME_APPROVED', handleSocketPlayGame);
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener('resize', handleResize);
      socket.off('PLAY_GAME_APPROVED', handleSocketPlayGame);
    };



  }, []);
  const handleTouch = () => {
    if (gameMode == 2) {
      socket.emit('message', JSON.stringify({
        cmd: 'PLAY_GAME',
        role: role
      }));
    } else {
      props.onPlay();
    }
  };
  /* ================================ For Mobile Responsive ===============================*/

  const fadeAnim = useRef(new Animated.Value(0)).current;

  return (
    <TouchableOpacity onPress={handleTouch} activeOpacity={0.8}>
    <Animated.View style={{
      background: 'black',
      width: isPC ? '600px' : '350px',
      height: isPC ? '394px' : '272px',
      display: 'flex', flexDirection: 'column',
      justifyContent: 'center', alignItems: 'center',
      rowGap: '20px',
      paddingTop: '10px',
      // paddingBottom: '10px',
      resizeMode: "contain",
      border: '2px solid gray',
      borderRadius: '20px',
      opacity: fadeAnim
    }}>

      <Text style={{
        fontSize: isPC ? '96px' : '64px',
        color: '#FDC6D3',
        WebkitTextStroke: '1px #EF587B',
        filter: 'drop-shadow(0px 0px 20px #EF587B)',
        fontWeight: '700',
        // textShadow: '0 0 5px #fff',
        fontFamily: 'Horizon'
      }}>
         Play MOBBER
      </Text>
      <View style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '5px',
      }}>
        <Text style={{
          textAlign: 'center',
          fontSize: '20px',
          fontWeight: '900',
          color: 'white',
          fontFamily: 'Horizon'
        }}>
          Press Space or Touch to start

        </Text>
        
      </View>
      
    </Animated.View>
    </TouchableOpacity>
  );
}

export default GameStart;

